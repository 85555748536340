import { iBlog } from "../interfaces/blog.interface";

export const BlogTop: iBlog = {
    link: "/blog/comment-nettoyer-sa-brosse-a-cheveux",
    title: "Comment <strong>nettoyer sa brosse</strong>&nbsp;?",
    baseline: `Entre les résidus de produits capillaires et les petites particules et poussières indésirables, nos brosses à cheveux méritent bien un peu d'attention.`,
    cover: {
        jpg: "/assets/img/blog/clean_brush.png",
        webp: "/assets/img/blog/clean_brush.webp"
    },
    subheading: "Conseil d'Audrey",
}

